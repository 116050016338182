import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideTop = keyframes`
  to {
    transform: translateY(0);
  }
`

const ContactLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin-left: auto;
  padding: 5% 5vw 10% 0;

  @media (max-width: ${breakpoints.screenLG}) {
    width: 85vw;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    width: 100vw;
    padding: 5% 9vw 8%;
  }

  .text {
    margin-bottom: 3%;
    opacity: 0;
    transform: translateY(8vh);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideTop} 1.6s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;

    :nth-child(1) {
      animation-delay: 1.1s;
    }

    :nth-child(2) {
      animation-delay: 1.15s;
    }
  }

  .contact-container {
    display: flex;
    width: 100%;
    padding-top: 4%;
    opacity: 0;
    transform: translateY(8vh);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideTop} 1.8s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 1.2s;
  }

  .contact {
    width: 40%;
  }

  .social-title {
    font-size: 0.9vw;
    padding-bottom: 3%;
    opacity: 0.6;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 1.8vw;
    }
  }

  .social-links {
    display: flex;
    margin-bottom: 12%;

    a {
      padding-right: 2vw;

      :hover {
        color: ${({ theme }) => theme.textHighlight};
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 30vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-left: 6vw;
      max-width: 45vw;
    }

    button {
      min-width: 11vw;
      margin-top: 4%;
    }
  }

  .form-control {
    padding-bottom: 8%;

    input,
    textarea {
      font-size: 0.9vw;
      border: 0;
      font-family: 'Source Sans Pro', sans-serif;
      border-bottom: 0.06em solid ${({ theme }) => theme.textPrimary};
      background-color: transparent;
      width: 100%;
      color: ${({ theme }) => theme.textPrimary};
      padding-bottom: 4%;

      @media (max-width: ${breakpoints.screenLG}) {
        font-size: 1.8vw;
      }

      ::placeholder {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.85vw;
        letter-spacing: 0.08em;

        @media (max-width: ${breakpoints.screenLG}) {
          font-size: 1.6vw;
        }
      }
    }
  }
`

export { ContactLayout }
