import React, { useState } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'

import SEO from '../components/SEO'
import { ContactLayout } from '../styles/ContactLayout'
import { ButtonSecondary } from '../styles/ButtonLayout'
import {
  Headline,
  Subtitle,
  ParagraphM,
  ParagraphS,
} from '../styles/Typography'

const ContactPage = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const isInvalid =
    name === '' || email === '' || subject === '' || message === ''

  return (
    <>
      <SEO title="Contact" />
      <ContactLayout>
        <Headline className="text">Get in touch</Headline>
        <Subtitle className="text">
          to order your own colorized <br />
          photo or just say hi.
        </Subtitle>
        <div className="contact-container">
          <div className="contact">
            <div className="contact-group">
              <ParagraphS className="social-title">Email</ParagraphS>
              <div className="social-links">
                <ParagraphM>
                  <a href="mailto:hi@colorized.mk">hi@colorized.mk</a>
                </ParagraphM>
              </div>
            </div>
            <div className="contact-group">
              <ParagraphS className="social-title">Social</ParagraphS>
              <div className="social-links">
                <ParagraphM>
                  <a
                    href="https://www.instagram.com/colorizedmk"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </ParagraphM>
                <ParagraphM>
                  <a
                    href="https://www.facebook.com/colorizedmk"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </ParagraphM>
                <ParagraphM>
                  <a
                    href="https://www.pinterest.com/colorizedmk"
                    target="_blank"
                  >
                    Pinterest
                  </a>
                </ParagraphM>
              </div>
            </div>
            <div className="contact-group">
              <ParagraphS className="social-title">Location</ParagraphS>
              <div className="social-links">
                <ParagraphM>
                  <a
                    href="https://goo.gl/maps/9BaBvdLcD23TpwP17"
                    target="_blank"
                  >
                    Bitola, Macedonia
                  </a>
                </ParagraphM>
              </div>
            </div>
          </div>
          <div className="form">
            <form
              name="contact"
              method="POST"
              action="/success"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <div className="form-control">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </div>
              <div className="form-control">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                />
              </div>
              <div className="form-control">
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={subject}
                  onChange={({ target }) => setSubject(target.value)}
                />
              </div>
              <div className="form-control">
                <textarea
                  type="text"
                  name="message"
                  placeholder="Message"
                  value={message}
                  rows="5"
                  onChange={({ target }) => setMessage(target.value)}
                />
              </div>
              <ButtonSecondary type="submit" disabled={isInvalid}>
                Send
              </ButtonSecondary>
            </form>
          </div>
        </div>
      </ContactLayout>
    </>
  )
}

export default ContactPage
